<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="mem_container resume">
            <div class="mem_info_contents">
                <div class="mem_contents_header">
                    <div class="title-txt">내 프로필 관리<br/><span>이력서 및 소속사 관리 / NCS 직무자가평가</span></div>
                </div>
                <div class="tab-type1">
                    <ul class="tab">
                        <li class="on"><a><img src="/images/tec/prf/tab1.png" alt="이력서탭"/>이력서</a></li>
                        <li @click="resPageMove()">
                            <!-- <div v-if="asgRegYn == 'N'" class="alarm show"><img src="/images/tab_alarm.png" alt="미등록항목알람"><p>미등록 항목이 있습니다!</p></div> -->
                            <a><img src="/images/tec/prf/tab3.png" alt="소속사관리탭"/>소속사 관리</a>                            
                        </li>
                        <li @click="moveNCS()"><a><img src="/images/tec/prf/tab2.png" alt="직무자가평가탭"/>NCS 직무 자가평가</a></li>
                    </ul>
                </div>
                <div class="contents-body tab">

                    <tec-resume ref="tecResume" :tecMberSeq="this.$store.state.userInfo.mberSeq"/>
                    
                    <!-- 하단 버튼 -->
                    <div class="select_btn" v-if="isMounted">
                        <div class="btn_01" @click="cancel()" v-if="auth('작성자')">취소</div>
                        <div class="btn_02" @click="save()" v-if="auth('작성자') && !auth('등록')" style="background-color: #FF681D;">수정하기</div>
                        <div class="btn_02" @click="saveTemp()" v-if="auth('작성자') &&  auth('등록')" style="background-color: #fff;color:#414042;border:1px solid #58595B;">임시저장</div>
                        <div class="btn_03" @click="save()" v-if="auth('작성자') &&  auth('등록')" style="background-color: #17A1FF;">등록완료</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tecResume from "@/components/highpro/TecResume.vue";

export default {
    components: { tecResume },
    data() {
        return {
            isMounted: false,
            // asgRegYn : '',
            
        };
    },
    mounted(){
        // this.asgRegChk();
        this.isMounted = true;
    },
    methods: {
        // asgRegChk(){
        //     this.$.httpPost('/api/mem/prf/getMberAsgRegInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
        //         .then(res => {
        //             this.asgRegYn = res.data.mberAsgRegInfo.mberAsgRegYn;
        //         })
        //         .catch(err => {
        //             alert(err.response.data.error_description);
        //         });
        // },
        validSave(){
            //console.log('PRF101M01', 'validSave', this.$refs.tecResume.data);
            var data = this.$refs.tecResume.data;
            var check = false;

            //고등학교 이상 졸업
            for(var i1 in data.schoolList){
                if(data.schoolList[i1].schoolStatusCd == '00'){
                    check = true;
                    break;
                }
            }
            if(!check){
                alert('학력사항 고등학교 이상 졸업 사항은 필수사항입니다.');
                this.$refs.tecResume.$refs.school.focusOn();
                return false;
            }

            //프로젝트 경력이 재직경력에 포함되는지 체크
            // for(var i2 in data.projectList){
            //     var project = data.projectList[i2];
            //     if(project.joinStartYyyymm && project.joinEndYyyymm){
            //         check = false;
            //         for(var j2 in data.careerList){
            //             var career = data.careerList[j2];
            //             if(career.workStartYyyymm <= project.joinStartYyyymm && project.joinEndYyyymm <= career.workEndYyyymm){
            //                 if(project.workPatCd != career.workPatCd){
            //                     alert('입력하신 프로젝트 기간에 근무한 회사와 근무형태가 다릅니다.');
            //                     this.$refs.tecResume.$refs.project.focusOn(i2);
            //                     return false;
            //                 }
            //                 check = true;
            //                 break;
            //             }
            //         }
            //         if(!check){
            //             alert('입력하신 프로젝트 기간에 근무한 회사재직경력이 없습니다.\n해당 프로젝트 기간에 근무한 회사 재직경력을 확인해 주세요.');
            //             console.log();
            //             this.$refs.project.focusOn(i2);
            //             return false;
            //         }
            //     }
            // }

            return true;
        },
        save(){
            //console.log('PRF101M01', 'save', this.$refs.tecResume.data);
            var data = this.$refs.tecResume.data;
            if(!this.validSave()){
                return;
            }
            
            //첨부파일 처리
            var files = [];
            // if(data.profileImg){
            //     //이미지
            //     data.profile = data.profileImg.name;
            //     files.push(data.profileImg);
            // }
            for(var i in data.attaFileRelList){
                var attaFile = data.attaFileRelList[i];
                if(attaFile.file instanceof File){
                    //첨부파일이 있을 경우 첨부파일을 추가한다.
                    attaFile.uploadIndex = files.length;
                    attaFile.orgFileNm   = attaFile.file.name;
                    files.push(attaFile.file);
                } else if(!attaFile.attaFileSeq && attaFile.resumeAttaFileDataNm) {
                    //추가한 파일목록 중에 증빙서류 구분은 있는데 첨부파일이 포함되지 않은 경우.
                    //delete data.attaFileRelList[i];
                    alert('첨부파일을 추가해주세요.');
                    this.$refs.attaFile.focusOn(i);
                    return;
                }
            }
            this.$.fileUpload('IMG_PDF_DOC', files, '/api/mem/prf/saveResume', data )
                .then(() => {
                    //console.log('PRF101M01', 'saveResume', res.data);
                    if('Y' == data.regYn){
                        this.$.popup('/tec/prf/PRF101P02')
                            .then(res => {
                                if(res){
                                    this.moveNCS(true);
                                } else {
                                    this.$refs.tecResume.search();
                                }
                            })
                    } else {
                        alert('저장이 완료되었습니다.')
                        this.$refs.tecResume.search();
                    }
                })
                .catch(e => {
                    console.error(e, data);
                    alert("저장 중 에러가 발생했습니다.");
                });
        },
        saveTemp(){
            //console.log('PRF101M01', 'save', this.$refs.tecResume.data);
            var data = this.$refs.tecResume.data;
            this.$.httpPost('/api/mem/prf/saveResumeTemp', {'resumeTmpSaveCont' : JSON.stringify(data)})
                .then(() => {
                    alert("임시저장 되었습니다.\n임시저장 단계에는 첨부하신 파일을 저장하지 않습니다.");
                }).catch(this.$.httpErrorCommon);
        },
        cancel(){
            this.$router.go(-1);
        },

        moveNCS(afterSave){
            if(!afterSave && this.auth("등록")){
                this.$.popup('/tec/prf/PRF101P03');
                return;
            }
            this.$router.push('PRF102M02');
        },

        // 소속사 관리 페이지 이동
		resPageMove() {
			this.$router.push('PRF104M01');
		},

        auth(checks){
            //console.log('auth', this.$refs)
            return this.$refs.tecResume.auth(checks);
        }
    },
};
</script>